import dayjs from '../_snowpack/pkg/dayjs.js';
import cloneDeep from '../_snowpack/pkg/lodash/cloneDeep.js';
import localizedFormat from '../_snowpack/pkg/dayjs/plugin/localizedFormat.js';
import * as booting from './booting.js';
dayjs.extend(localizedFormat);
const numberFormatter = new Intl.NumberFormat(window.navigator.language, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});
export function sleep(ms) {
  return new Promise(r => setTimeout(r, ms));
}
export function formatDate(inputDate) {
  return inputDate ? dayjs(inputDate).format('ll') : '';
}
export function formatDateWithTime(inputDate) {
  return inputDate ? dayjs(inputDate).format('lll') : null;
}
export function formatNumber(number) {
  return numberFormatter.format(number);
}
export function ensureValidComponentsModel({
  components,
  shape
} = {}) {
  var _shape$components;

  return shape === null || shape === void 0 ? void 0 : (_shape$components = shape.components) === null || _shape$components === void 0 ? void 0 : _shape$components.map(componentStructure => {
    const component = components === null || components === void 0 ? void 0 : components.find(c => c.id === componentStructure.id || c.componentId === componentStructure.id); // TODO: is this still valid? because in the case of the numeric components uni can be null be we might have a decimalPLaces value

    /**
     * If the content property is null, the we need to make the all content = null
     * e.g.:
     * content: {
     *  text: null
     * }
     *
     * become -> content: null
     */

    for (const property in component === null || component === void 0 ? void 0 : component.content) {
      if (!component.content[property] && component.type !== 'numeric') {
        component.content = null;
        break;
      }
    } // Avoid trigger an updateProduct mutation when the content is null


    if (component.type === 'contentChunk' && component.content === null) {
      component.content = {
        chunks: []
      };
    }

    return {
      id: componentStructure.id,
      type: componentStructure.type,
      content: null,
      ...(component || {})
    };
  });
}
export const customComponentHandler = {
  boolean(data) {
    if (!data || !data.content) {
      return {
        value: false
      };
    }

    return data.content;
  },

  singleLine(data) {
    if (!data || !data.content) {
      return {
        text: null
      };
    }

    return data.content;
  },

  richText(data) {
    if (!data) {
      return null;
    }

    let {
      content
    } = data; // Do not allow content: { json: null } [ch203]

    if (content && 'json' in content && !content.json) {
      return null;
    }

    if (!content) {
      return null;
    }

    return content;
  },

  image(data) {
    if (!data) {
      return data;
    }

    const {
      id,
      url,
      tempId,
      preview,
      file,
      upload,
      bucketKey,
      variants,
      isUploading,
      uploadProgress,
      fileType,
      sizes,
      ...content
    } = data;
    const result = {
      key: bucketKey,
      ...content
    };
    /**
     * if the object property is null, we can actually delete it
     */

    for (const key in result) {
      if (!result[key]) {
        delete result[key];
      }
    }

    return result;
  },

  images({
    content
  }) {
    if (!content || !content.images || content.images.length === 0) {
      return null;
    }

    return content.images.filter(image => !!image.bucketKey || !!image.key).map(customComponentHandler.image);
  },

  video(video) {
    return {
      key: video.bucketKey || video.id,
      ...(video.title && {
        title: video.title
      }),
      ...(video.thumbnails && {
        thumbnails: video.thumbnails.map(customComponentHandler.image)
      })
    };
  },

  videos({
    content
  }) {
    if (!content || !content.videos || content.videos.length === 0) {
      return null;
    }

    return content.videos.map(customComponentHandler.video).filter(Boolean);
  },

  paragraphCollection(component) {
    const {
      content
    } = component;
    let paragraphs = [];

    if (content !== null && content !== void 0 && content.paragraphs) {
      paragraphs = content.paragraphs.map(paragraph => {
        const {
          images,
          videos,
          title
        } = paragraph;
        let {
          body
        } = paragraph; // Do not allow body: { json: null } [ch203]

        if (body && 'json' in body && !body.json) {
          body = null;
        }

        return {
          body,
          title,
          images: (images === null || images === void 0 ? void 0 : images.length) > 0 ? images.filter(image => !!(image !== null && image !== void 0 && image.bucketKey)).map(customComponentHandler.image) : null,
          videos: (videos === null || videos === void 0 ? void 0 : videos.length) > 0 ? videos.filter(Boolean).filter(video => !!(video !== null && video !== void 0 && video.id) || !!(video !== null && video !== void 0 && video.tempId)).map(customComponentHandler.video) : null
        };
      });
    }

    return {
      paragraphs
    };
  },

  itemRelations(component) {
    var _component$content, _component$content$it;

    const ids = (component === null || component === void 0 ? void 0 : (_component$content = component.content) === null || _component$content === void 0 ? void 0 : (_component$content$it = _component$content.items) === null || _component$content$it === void 0 ? void 0 : _component$content$it.map(item => item.id)) || [];
    return {
      itemIds: ids
    };
  },

  gridRelations(component) {
    var _component$content2, _component$content2$g;

    const ids = (component === null || component === void 0 ? void 0 : (_component$content2 = component.content) === null || _component$content2 === void 0 ? void 0 : (_component$content2$g = _component$content2.grids) === null || _component$content2$g === void 0 ? void 0 : _component$content2$g.map(item => item.id)) || [];
    return {
      gridIds: ids
    };
  },

  contentChunk(component) {
    var _component$content$ch;

    if (!component.content || !((_component$content$ch = component.content.chunks) !== null && _component$content$ch !== void 0 && _component$content$ch.length)) {
      return null;
    }

    const transformedChunks = component.content.chunks.map(transformToComponentInput);
    return {
      chunks: transformedChunks
    };
  },

  componentChoice(component) {
    //* reset the selected component
    if (!component.content || !component.content.selectedComponent) {
      return null;
    }

    return transformToComponentInput([component.content.selectedComponent])[0];
  },

  numeric(component) {
    var _parseFloat;

    if (!component.content) {
      return null;
    }

    return {
      number: (_parseFloat = parseFloat(component.content.number)) !== null && _parseFloat !== void 0 ? _parseFloat : 0,
      unit: component.content.unit
    };
  },

  datetime(component) {
    if (!component.content) {
      return null;
    }

    return component.content;
  },

  propertiesTable(component) {
    if (!component.content) {
      return null;
    }

    return component.content;
  },

  location(component) {
    if (!component.content) {
      return null;
    }

    return component.content;
  },

  selection(component) {
    if (!component.content || !component.content.options) {
      return null;
    }

    return {
      keys: component.content.options.map(({
        key
      }) => key)
    };
  }

};
export function transformToComponentInput(components) {
  return components.map(cmp => {
    const {
      id,
      componentId,
      type,
      name,
      ...rest
    } = cmp || {};
    const componentRest = cloneDeep(rest);
    const customHandler = customComponentHandler[type];
    return {
      componentId: id || componentId,
      [type]: customHandler ? customHandler(componentRest) : componentRest.content
    };
  });
}
export function removePropertiesFromObject(properties, obj) {
  const handled = []; // Clone the object

  const merged = cloneDeep(obj);
  let props = properties;

  if (!Array.isArray(properties)) {
    props = [properties];
  }

  function handleNode(node) {
    if (!node || handled.includes(node)) {
      return node;
    }

    handled.push(node);
    const keys = Object.keys(node);
    const subscriptionPlansIndex = keys.indexOf('subscriptionPlans'); // NOTE: we don't want to remove id from subscriptionPlans !!!

    if (subscriptionPlansIndex > -1) {
      keys.splice(subscriptionPlansIndex, 1);
    }

    keys.forEach(key => {
      if (props.includes(key)) {
        delete node[key];
      } else if (Array.isArray(node[key])) {
        node[key].forEach(handleNode);
      } else if (typeof node[key] === 'object') {
        handleNode(node[key]);
      }
    });
  }

  handleNode(merged);
  return merged;
}
export function hideBootingScreen() {
  booting.hide();
}
export function allItemsHaveValue(items) {
  if (!items) {
    return false;
  }

  if (items.length === 0) {
    return true;
  }

  return items.every(item => {
    return !!item;
  });
}
export function isFunction(variable) {
  return !!variable && typeof variable === 'function';
}
export const toSet = array => Array.from(new Set(array));